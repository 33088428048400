import React from 'react'
import {Box,Text,Heading,Flex} from '@chakra-ui/react'
import MeinSEO from '../components/SEO'
import {StaticImage} from 'gatsby-plugin-image'


const Cholera = () => {
  return(
    <>
      <MeinSEO title="Heinrich Heine: Ich rede von der Cholera"
  description="Das Theater Willy Praml inzeniert multimedial den Cholera-Bericht von H.Heine"
  keywords="Pandemie, Cholera Paris 1932, Heinrich Heine und die Cholera"
  />
     <Heading mt="10" pt="3" pl="3" variant="titel">Heine: Ich rede von der Cholera...</Heading>
<Flex  flexWrap="wrap" mx="4" maxWidth="1200">
 <Box pr={[0,0,3]} width={["100%","100%","55%"]}>
 

 
  
    
    
   
    <StaticImage src="../images/web/teaser-1.jpg"
   
     alt="Teaser zu Heine"
    />
     
  
  

    <Text>Epidemische Krisen. Nichts Neues!</Text>
    <Text>Heine berichtet von der <span style={{fontWeight:500}}>Cholera 1832 in Paris</span>.
     Vom Karneval, bei der die Maskierten blau anlaufen, reihenweise tot umfallen und hektisch
in ihren Narrenkostümen beerdigt werden. <br/>Von den Verschwörungstheoretikern, 
die das Volk so in Rage versetzen, dass eine Horde zwei vermeintliche Giftmischer
 auf offener Straße in Stücke reißt. Von dem Verkehrsstau hunderter Leichenwagen, 
 die keine Zufahrt mehr zum Friedhof finden und deren Kutscher in Streit geraten und 
 sich gegenseitig die Toten aus den Wagen zerren.</Text>
   

  
  <Text>HORROR-Szenarien, die Heine uns da in den düstersten Farben und gewohnt bissiger Ironie ausmalt, die einem aber irgendwie bekannt vorkommen oder inzwischen zumindest vorstellbar.<br/> Die 200 Jahre, die zwischen uns und Heines Paris liegen, schrumpfen zu einem Zeitmaß von einer Markus-Lanz-Talkrunde bis zur nächsten zusammen. 
  Und die verwirrte Stimmung der Leser*innen oder Fernsehenden ist die gleiche. <br/>Nichts Neues!<br/><br/>
  Heines Cholera-­Bericht wird im Dialog stehen mit dem 3. Akt der Oper
LA TRAVIATA von Verdi – das todtraurig vertonte Sterbezimmer einer kranken Schönen. Der Schrecken im Theater soll ja immer auch schön sein und
nicht tödlich.
</Text>

</Box>
<Box shadow="md" bg="gray.200" p="3"  flex="1 1">

  
 
  <Heading variant="title"  size="lg">Regie</Heading><Text>Michael Weber</Text>
  <Heading variant="title"  size="lg">Kostüm</Heading><Text>Paula Kern</Text>



      <Heading variant="title"  size="lg">Darsteller*innen</Heading>
      <Text>Hannah Bröder. Jakob Gail. Muawia Harb.<br/> Birgit Heuser. Sam Michelson. Anna Staab. <br/>Willy Praml.<br/>
Sowie drei Tenöre des Heinrich-Heine-Chors Frankfurt:<br/>Manuel Campos, Werner Heinz, Herbert Obenland</Text>
 




  <Heading variant="title"  size="lg">Ort</Heading><Text >Mittelrheinhalle, Bacharach, Mainzer Straße.</Text>
  <Heading variant="title"  size="lg">Datum</Heading><Text>Freitag, 1. Juli 2022 19:30 Uhr<br/>
  Samstag, 2. Juli 2022 19:30 Uhr<br/> 
  Sonntag, 3. Juli 2022 19:30 Uhr<br/>
</Text>

  </Box>
</Flex>

 </>
  )

}
 export default Cholera


  